/*! CSS Used from: Embedded */

/*! CSS Used from: https://www.okno-moskva.ru/assets/compile/067249e360daf093f3aac3d4f1c99975.css?v=1617961631 */
.fancybox-slide{position:absolute;top:0;left:0;width:100%;height:100%;margin:0;padding:0;overflow:auto;outline:none;white-space:normal;box-sizing:border-box;text-align:center;z-index:99994;-webkit-overflow-scrolling:touch;display:none;-webkit-backface-visibility:hidden;backface-visibility:hidden;transition-property:opacity, -webkit-transform;transition-property:transform, opacity;transition-property:transform, opacity, -webkit-transform;-webkit-transform-style:preserve-3d;transform-style:preserve-3d;}
.fancybox-slide::before{content:'';display:inline-block;vertical-align:middle;height:100%;width:0;}
.fancybox-slide--current{display:block;}
.fancybox-slide > div{display:inline-block;position:relative;padding:24px;margin:44px 0 44px;border-width:0;vertical-align:middle;text-align:left;background-color:#eeeeee;overflow:auto;box-sizing:border-box;-webkit-box-shadow:0px 10px 15px 0px rgba(0, 0, 0, 0.1);box-shadow:0px 10px 15px 0px rgba(0, 0, 0, 0.1);}
.fancybox-close-small{position:absolute;top:11px;right:11px;width:30px;height:30px;padding:0;margin:0;border:0;border-radius:0;outline:none;background:transparent;z-index:10;cursor:pointer;}
.fancybox-close-small:after{content:'';position:absolute;top:0px;right:0px;width:30px;height:30px;font:20px/30px Arial,"Helvetica Neue",Helvetica,sans-serif;color:#bbbbbb;font-weight:300;text-align:center;border-radius:50%;border-width:0;background:url(https://www.okno-moskva.ru/assets/7bf6c5ca/fancybox-close.png) 50% 50%/12px auto no-repeat;box-sizing:border-box;z-index:2;}
.fancybox-close-small:focus:after{outline:1px dotted #888;}
.fancybox-close-small:hover:after{color:#555;}
.b-form{border:solid #e5e5e5;border-width:0;background-color:transparent;padding:0;margin-bottom:20px;}
.b-form *,.b-form *:active,.b-form *:focus{outline:none;-webkit-tap-highlight-color:transparent;}
.b-form .form__header h2{margin:0 0 34px;padding:0 0 10px;border:solid #e5e5e5;border-width:0;font-size:30px;font-weight:800;font-style:normal;font-family:Montserrat, sans-serif;text-align:center;color:#222222;}
.b-form .form__mark{text-decoration:none;color:#d13d73;}
.b-form .form__input--checkbox{padding-bottom:10px;}
.b-form .form__input--checkbox input[type="checkbox"]{position:absolute;opacity:0;pointer-events:none;}
.b-form .form__input--checkbox .form__checkbox{padding-right:10px;margin-right:-.275em;display:inline-block;vertical-align:middle;}
.b-form .form__input--checkbox .form__checkbox .form__checkbox-trigger{border:1px solid #e5e5e5;-webkit-border-radius:2px;-moz-border-radius:2px;border-radius:2px;background:#fff;display:block;width:25px;height:25px;position:relative;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
.b-form .form__input--checkbox .form__checkbox .form__checkbox-trigger svg{position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%,-50%);-moz-transform:translate(-50%,-50%);transform:translate(-50%,-50%);font-size:10px;line-height:1;display:none;}
.b-form .form__input--checkbox:hover .form__checkbox-trigger{border-color:#e5e5e5;}
.b-form .form__input--checkbox .form__checkbox-label{margin-right:-.275em;display:inline-block;vertical-align:middle;width:calc(100% - 35px);}
.b-form .form__input--checkbox input[type="checkbox"]:checked ~ .form__checkbox .form__checkbox-trigger{border-color:#e5e5e5;}
.b-form .form__input--checkbox input[type="checkbox"]:checked ~ .form__checkbox .form__checkbox-trigger svg{display:block;color:#222;}
.b-form .form__label{font-family:Montserrat, sans-serif;font-weight:bold;font-size:14px;}
.b-form .form__item{position:relative;}
.b-form .form__item--label-none .form__label{display:none;}
.b-form .form__item--agree a:link,.b-form .form__item--agree a:visited{line-height:1.1;text-decoration:none;}
.b-form .form__item--agree a:hover,.b-form .form__item--agree a:active{border-bottom-color:transparent;text-decoration:none;}
.b-form .form__item--icon .form__icon{position:absolute;right:15px;top:50%;-webkit-transform:translateY(-50%);-moz-transform:translateY(-50%);transform:translateY(-50%);pointer-events:none;z-index:5;}
.b-form .form__item--icon .form__input{position:relative;}
.b-form .form__errors{padding:20px;border:1px solid #d13d73;background:#fff1f6;color:#d13d73;}
.b-form .form__errors ul{margin:0;padding:15px 0 15px 20px;}
.b-form .form__cols-wrap{margin:0 -5px;display:-webkit-flex;display:flex;-webkit-flex-flow:row wrap;flex-flow:row wrap;}
.b-form [class^="form__col-"]{-webkit-flex:1 0 auto;flex:1 0 auto;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;padding:5px 5px;}
.b-form .form__col-1{width:100%;}
.b-form .form__item--agree .form__checkbox{display:none;}
.b-form .form__item--agree .form__checkbox-label{margin-right:0;display:block;width:auto;font-size:12px;line-height:1.833;}
.b-form--help{max-width:410px;margin:0 auto;padding:20px 0 27px;}
.b-form--help .form__cols-wrap{margin:0;}
.b-form--help [class^="form__col-"]{padding:0;}
.b-form--help .form__item--btns button{width:100%;}
.b-form--help .form__item--agree{padding:25px 0;text-align:center;}
.b-btnbox{margin:0;width:220px;padding:20px 10px 21px;border:none;cursor:pointer;display:inline-block;vertical-align:middle;color:#fff;font-family:Montserrat, sans-serif;font-size:16px;font-weight:500;text-align:center;text-decoration:none;box-sizing:border-box;-webkit-border-radius:2px;-moz-border-radius:2px;border-radius:2px;background:#d13d73;background:-moz-linear-gradient(top, #d13d73 0%, #d13d73 100%);background:-webkit-gradient(linear, left top, left bottom, color-stop(0%,#d13d73), color-stop(100%,#d13d73));background:-webkit-linear-gradient(top, #d13d73 0%,#d13d73 100%);background:-o-linear-gradient(top, #d13d73 0%,#d13d73 100%);background:-ms-linear-gradient(top, #d13d73 0%,#d13d73 100%);background:linear-gradient(to bottom, #d13d73 0%,#d13d73 100%);filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#d13d73', endColorstr='#d13d73',GradientType=0 );-webkit-transition:box-shadow .2s;transition:box-shadow .2s;}
#exampleModal222 .b-btnbox:hover,.b-btnbox:active{box-sizing:border-box;color:#fff;text-decoration:none;-webkit-border-radius:2px;-moz-border-radius:2px;border-radius:2px;background:#d13d73;background:-moz-linear-gradient(top, #d13d73 0%, #d13d73 100%);background:-webkit-gradient(linear, left top, left bottom, color-stop(0%,#d13d73), color-stop(100%,#d13d73));background:-webkit-linear-gradient(top, #d13d73 0%,#d13d73 100%);background:-o-linear-gradient(top, #d13d73 0%,#d13d73 100%);background:-ms-linear-gradient(top, #d13d73 0%,#d13d73 100%);background:linear-gradient(to bottom, #d13d73 0%,#d13d73 100%);filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#d13d73', endColorstr='#d13d73',GradientType=0 );-webkit-box-shadow:0px 5px 20px 0px rgb(209, 61, 115);box-shadow:0px 5px 20px 0px rgb(209, 61, 115);}


/* h2{color:#222222;font-family:Montserrat, sans-serif;font-size:38px;font-style:normal;font-weight:800;margin:0 0 50px;text-decoration:none;text-shadow:0 0 0 rgba(0, 0, 0, 0);line-height:1.2;}
#exampleModal222 a:link,a:visited{color:#d13d73;text-decoration:none;}
#exampleModal222 a:hover,a:active{color:#d13d73;text-decoration:underline;}
#exampleModal222 input:focus{outline:none;}
#exampleModal222 input{-webkit-appearance:none;-moz-appearance:none;appearance:none;}
#exampleModal222 .b-callbackform-wrap > .b-form{margin-bottom:0;} */




/*! CSS Used from: Embedded */
/* svg{transform:translateZ(0);shape-rendering:geometricPrecision;}
button{cursor:pointer;} */
/*! CSS Used fontfaces */
/* @font-face{font-family:'Montserrat';src:url('https://www.okno-moskva.ru/files/fonts/Montserrat/Montserrat-Regular.woff2') format('woff2');font-weight:normal;font-style:normal;font-display:fallback;}
@font-face{font-family:'Montserrat';src:url('https://www.okno-moskva.ru/files/fonts/Montserrat/Montserrat-RegularCyr.woff2') format('woff2');font-weight:normal;font-style:normal;font-display:fallback;}
@font-face{font-family:'Montserrat';src:url('https://www.okno-moskva.ru/files/fonts/Montserrat/Montserrat-Medium.woff2') format('woff2');font-weight:500;font-style:normal;font-display:fallback;}
@font-face{font-family:'Montserrat';src:url('https://www.okno-moskva.ru/files/fonts/Montserrat/Montserrat-MediumCyr.woff2') format('woff2');font-weight:500;font-style:normal;font-display:fallback;}
@font-face{font-family:'Montserrat';src:url('https://www.okno-moskva.ru/files/fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2');font-weight:600;font-style:normal;font-display:fallback;}
@font-face{font-family:'Montserrat';src:url('https://www.okno-moskva.ru/files/fonts/Montserrat/Montserrat-SemiBoldCyr.woff2') format('woff2');font-weight:600;font-style:normal;font-display:fallback;}
@font-face{font-family:'Montserrat';src:url('https://www.okno-moskva.ru/files/fonts/Montserrat/Montserrat-ExtraBold.woff2') format('woff2');font-weight:800;font-style:normal;font-display:fallback;}
@font-face{font-family:'Montserrat';src:url('https://www.okno-moskva.ru/files/fonts/Montserrat/Montserrat-ExtraBoldCyr.woff2') format('woff2');font-weight:800;font-style:normal;font-display:fallback;} */